<!-- Start Feedback Area -->
<div class="container">
    <div class="section-title" *ngFor="let Title of sectionTitle;">
        <h2>{{Title.title}}</h2>
        <p>{{Title.paragraph}}</p>
    </div>

    <div class="feedback-slides">
        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let Content of singleFeedbackBox;">
                <div class="single-feedback-box">
                    <div class="rating">
                        <i class='{{Star.icon}}' *ngFor="let Star of Content.rating"></i>
                    </div>
                    <p>{{Content.paragraph}}.</p>
                    <div class="client-info">
                        <div class="d-flex align-items-center">
                            <img [src]="Content.authorImg" alt="image">
                            <div class="title">
                                <h3>{{Content.authorName}}</h3>
                                <span>{{Content.authorDesignation}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>
<!-- End Feedback Area -->
