import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/appviews/home/home.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { InvoiceComponent } from './components/appviews/invoice/invoice.component';
import { NotAvailableComponent } from './components/appviews/not-available/not-available.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'invoice', component: InvoiceComponent },
  { path: 'NotAvailable', component: NotAvailableComponent },

    // Here add new pages component

    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
