import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppLoaderService } from '../../common/app-loader/app-loader.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  public dataSourceInvoice: any;
  public dataSourceElement: any;
  thisID: number = 0;
  ComID: number = 1;
  private datas: any;
  private sub: any;
  invoiceType: string = '';

  constructor(private http: HttpClient, 
    public translate: TranslateService, private loader: AppLoaderService,
    private route: ActivatedRoute, private router: Router,
    private toastr: ToastrService

  ) { }

  ngOnInit(): void {


    try {


      var phoneNo = '';
      var InvID = 0;


      this.sub = this.route.params.subscribe(params => {
        phoneNo = params['PhNo'];
        InvID = +params['InvID'];
      });

     this.loader.open();
      this.getServiceID(InvID, phoneNo, this.ComID)
        .subscribe((res) => {
          this.loader.close();
          this.dataSourceInvoice = res[0];

          this.getServiceElementID(InvID, this.ComID)
            .subscribe((res) => {
              this.dataSourceElement = res;

              for (var i = 0; i < this.dataSourceElement.length; i++) {

                var totals = this.dataSourceElement[i].quantity * this.dataSourceElement[i].price;
                var discount = (this.dataSourceElement[i].discountPercentage / 100) * totals;
                totals = totals - discount;
                var VAT = (this.dataSourceElement[i].taxPercentage / 100) * totals;
                this.dataSourceElement[i].total = (totals + VAT);
              }


            });

        },
          (err: any) => {
           this.loader.close();

            var transR = this.translate.instant('No_data.');
            this.toastr.error(transR, '');

            this.router.navigate(['/NotAvailable', {}]);
          });


    } catch (e) {
      var transR = this.translate.instant('No_data.');
      this.toastr.error(transR, '');

      this.router.navigate(['/NotAvailable', {}]);
    }

  }


  onDownload(fileType) {
   
    this.loader.open();
    this.getInvoiceDetailsPrint(this.dataSourceInvoice.inInvoiceID, this.ComID, fileType, "public").subscribe(res => {
      this.loader.close();
      let blob: Blob = res.body as Blob;

      var ext = '';
      if (fileType == 'PDF') {
        ext = '.pdf';
      }
      else if (fileType == 'Excel') {
        ext = '.xls';
      }
      else if (fileType == 'Word') {
        ext = '.doc';
      }
      else if (fileType == 'Image') {
        ext = '.jpeg';
      }


      saveAs(blob, this.dataSourceInvoice.invoiceType + '-' + this.dataSourceInvoice.inInvoiceNo + ext);


    },
      err => {
        this.loader.close(); console.log(err);
      }
    );
  }


  getServiceID(invoiceID: number, phoneNo: string, comID: number) {
    var ss = environment.apiURL + 'Invoice/searchInvoice/?comID=' + comID + '&invoiceID=' + invoiceID + '&phoneNo=' + phoneNo;
    return this.http.get(environment.apiURL + 'Invoice/searchInvoice/?comID=' + comID + '&invoiceID=' + invoiceID + '&phoneNo=' + phoneNo );
  }

  getServiceElementID(invoiceID: number, comID: number) {
    return this.http.get(environment.apiURL + 'Invoice/searchElements/?comID=' + comID + '&invoiceID=' + invoiceID );
  }



  getInvoiceDetailsPrint(id: number, comID: number, fileType: string, empName: string) {
    

    var search = "?id=" + id + "&comID=" + comID + "&fileType=" + fileType + "&empName=" + empName;
    return this.http.get(environment.apiURL + 'Invoice/InvoiceDetailsPrint/' + search, { observe: 'response', responseType: 'blob' });
  }


}
