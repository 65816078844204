<!-- Start Main Banner Area -->
<div class="main-banner-area">
  <div class="container" *ngIf="SelectedLanguage == 'en'">
    <div class="main-banner-content" *ngFor="let Content of mainBannerContent;">
      <h1>
        {{Content.title}}
        <ngx-typed-js [strings]="['Foodstuffs', 'Vegetables and Fruits', 'Accessories', 'Electrical devices', 'Butchery', 'Fish', 'Bakery']" [typeSpeed]="100" [loop]="true" [backSpeed]="100" [showCursor]="false">
          <span class="typing"></span>
        </ngx-typed-js>
      </h1>
      <p>{{Content.paragraph}}<br /></p>


    </div>
  </div>
  <div class="container" *ngIf="SelectedLanguage == 'ar'">
    <div class="main-banner-content" *ngFor="let Content of mainBannerContentAr;">
      <h1>
        {{Content.title}}
        <ngx-typed-js [strings]="['مخبوزات', 'أسماك', 'ملحمة', 'أجهزة كهربائية', 'كماليات', 'خضروات وفواكة', 'المواد غذائية']" [typeSpeed]="100" [loop]="true" [backSpeed]="100" [showCursor]="false">
          <span class="typing"></span>
        </ngx-typed-js>
      </h1>
      <p>{{Content.paragraph}}<br /></p>


    </div>
  </div>
</div>
<!-- End Main Banner Area -->
