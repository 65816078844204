<app-navbar-style-one></app-navbar-style-one>

<section class="error-area bg-f9f9f9 pt-100">
  <div class="container">

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area">
     
      <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
       
      </ol>
    </div>


    <div class="invoice-area">
      <br />  <br />

      <div class="d-flex justify-content-center mt-2">
        <h4 class="mb-2">{{ TextAr1 }}</h4>
      </div>
      <div class="d-flex justify-content-center mt-2">
        <p>{{TextAr2 }}</p>
      </div>
      <br />
      <br />
      <div class="d-flex justify-content-center mt-2">
        <h4 class="mb-2">{{ TextEn1 }}</h4>
      </div>
      <div class="d-flex justify-content-center mt-2">
        <p>{{TextEn2 }}</p>
      </div>




    </div>



    <br />
    <br />
    <br />

    <app-copyrights></app-copyrights>

  </div>
</section>
