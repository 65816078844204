<!-- Start Category Area -->
<div class="container">
  <div class="section-title" *ngFor="let Title of sectionTitle;">

    <h2>{{'Branches' | translate }}</h2>
    <p>{{'BranchesTXT' | translate }}</p>
  </div>

  <div class="row">
    <div class="col-lg-3 col-sm-6 col-md-4">
      <div class="single-category-box">
        <img src="./assets/img/values/Location-icon.png" />
        <h3>{{'Tharmad' | translate }}</h3>
        <!--<span>{{'Wide_CoverageTXT' | translate }}</span>-->

      </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
      <div class="single-category-box">
        <img src="./assets/img/values/Location-icon.png" />
        <h3>{{'Batha_Hilal' | translate }}</h3>

        <!--<span>{{'24_7_CommunicationsTXT' | translate }}</span>-->

      </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
      <div class="single-category-box">
        <img src="./assets/img/values/Location-icon.png" />
        <h3>{{'Al_Afrad' | translate }}</h3>

        <!--<span>{{'Ease_of_UseTXT' | translate }}</span>-->

      </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
      <div class="single-category-box">
        <img src="./assets/img/values/Location-icon.png" />
        <h3>{{'Al_Shreasah' | translate }}</h3>
        <!--<span>{{'Ease_of_UseTXT' | translate }}</span>-->

      </div>
    </div>
  </div>
</div>
<!-- End Category Area -->
