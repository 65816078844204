<!-- Start Features Area -->
<section class="features-area ptb-100">
    <div class="container">
      <div class="section-title">
        <h2>{{'About_Us' | translate }}</h2>
        <p>{{'About_UsTXT' | translate }}</p>
      </div>

      
    </div>

    <div class="divider"></div>
</section>
<!-- End Features Area -->
