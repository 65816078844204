<!-- Start Main Content Wrapper Area -->
<app-navbar-style-one></app-navbar-style-one>

<section class="error-area bg-f9f9f9 pt-100">
  <div class="container">

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" >
      <ol class="breadcrumb">
        <li class="item"><a routerLink="/">{{'Home' | translate }}</a></li>
      </ol>
    </div>
    <!-- End Breadcrumb Area -->
    <!-- Start -->
    <div class="invoice-area">
      <div class="invoice-header d-flex justify-content-between">
        <div class="invoice-left-text">
          <h3 class="mb-0">{{'Weaam' | translate }}</h3>
          <p class="mt-2 mb-0">{{'Branch' | translate }}: {{ dataSourceInvoice.branchName }}</p>
        </div>
        <div class="invoice-right-text">
          <h3 class="mb-0 text-uppercase">{{'Invoice' | translate }}</h3>
        </div>
      </div>
      <div class="invoice-middle">
        <div class="row">
          <div class="col-lg-3">
            <div class="text">
              <h4 class="mb-2">{{'Bill_To' | translate }}</h4>
              <span class="d-block mb-1">{{ dataSourceInvoice.accountName }}</span>
              <span class="d-block mb-1">{{ dataSourceInvoice.clientPhoneNO }}</span>
              <span class="d-block">{{ dataSourceInvoice.address }}</span>
            </div>
          </div>
          <div class="col-lg-3">

          </div>
          <div class="col-lg-6">
            <div class="text text-end">
              <h5>{{'Invoice_POS' | translate }}: <sub>{{ dataSourceInvoice.posInvoiceCode }}{{ dataSourceInvoice.posInvoiceNo }}</sub></h5>
              <h5>{{'Invoice_No' | translate }}: <sub>{{ dataSourceInvoice.inInvoiceNo }}</sub></h5>
              <h5>{{'Date' | translate }}: <sub>{{ dataSourceInvoice.date | date  : 'dd/MM/yyyy hh:mm:ss a' }}</sub></h5>

            </div>
          </div>
        </div>
      </div>

      <div class="invoice-table table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>{{'Description' | translate }}</th>
              <th>{{'Quantity' | translate }}</th>
              <th>{{'Total' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of dataSourceElement; let rowIndex = index;">
              <td> {{(rowIndex + 1)}} </td>
              <td>{{ item.sBarcode }} <br /> {{ item.itemName }}</td>
              <td class="text-end">{{ item.quantity }}</td>
              <td class="text-end">{{ item.total | number : '1.3-3' }}</td>
            </tr>


            <!--<tr>
          <td class="text-end" colspan="3"><strong>{{'VAT' | translate }}</strong></td>
          <td class="text-end">{{ dataSourceInvoice.taxAmount }}</td>
        </tr>-->
            <tr>
              <td class="text-end" colspan="3"><strong>{{'Total' | translate }}</strong></td>
              <td class="text-end">{{ dataSourceInvoice.amount  | number : '1.3-3' }}</td>
            </tr>
            <tr>
              <td class="text-end total" colspan="3"><strong>{{'Total_Paid' | translate }}</strong></td>
              <td class="text-end total-price"><strong>{{ dataSourceInvoice.totalPaid  | number : '1.3-3' }}</strong></td>
            </tr>
            <tr>
              <td class="text-end total" colspan="3"><strong>{{'Balance' | translate }}</strong></td>
              <td class="text-end total-price"><strong>{{ dataSourceInvoice.amount - dataSourceInvoice.totalPaid  | number : '1.3-3'   }}</strong></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row">
        <div class="col-lg-6 col-sm-6 col-md-6">
          <div class="invoice-btn-box">
            <a class="default-btn" (click)="onDownload('PDF')" href="javascript:void(0);"><i class='bx bxs-file-pdf'></i> {{'Download' | translate }}</a>

          </div>
          </div>
            <div class="col-lg-6 col-sm-6 col-md-6">
              <div class="single-footer-widget text-end">
                <br />
                <h6>{{'ContactUs' | translate }}</h6>

                <ul class="social-link">
                  <li><a href="https://www.instagram.com/alweaam.h.m" class="d-block" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                  <li><a href="https://www.tiktok.com/@alweaam.h.m" class="d-block" target="_blank"><i class="bx bxl-tiktok"></i></a></li>
                </ul>
              </div>


            </div>
          </div>
          </div>
        <!-- End -->

        <div class="flex-grow-1"></div>

        <app-copyrights></app-copyrights>

      </div>
</section>
<!-- End Main Content Wrapper Area -->
