<!-- Start Navbar Area -->
<div class="navbar-area navbar-style-two" style=" padding-top: 10px; padding-bottom:10px">
    <div class="vesax-responsive-nav">
        <div class="container">
            <div class="vesax-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/logo.png" alt="logo" style="height:60px;"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="vesax-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="logo" style="height:60px"></a>

                <div class="collapse navbar-collapse">


                  <ul class="navbar-nav">
                  
                  </ul>

                  <div class="others-option d-flex align-items-center">

                    <div class="dropdown language-switcher d-inline-block">
                      <button class="dropdown-toggle" type="button" (click)="toggleClassLang()">
                        <img src="{{CurrentLangSrc}}" alt="image">
                        <span style="color:white">{{CurrentLangName}} <i class='bx bx-chevron-down'></i></span>
                      </button>
                      <div class="dropdown-menu" [class.active]="classApplied">
                        <button (click)="setLang()" class="dropdown-item d-flex align-items-center">
                          <img src="{{SecondLangSrc}}" class="shadow-sm" alt="flag">
                          <span>{{SecondLangName}}</span>
                        </button>

                      </div>
                    </div>


                  </div>
                  </div>
</nav>
        </div>
    </div>

    
</div>
<!-- End Navbar Area -->

<!-- Start Login & Register Modal -->
<!-- End Login & Register Modal -->
