<!-- Start How It Works Area -->
<div class="container">
  <div class="section-title">

    <h2>{{'Sections' | translate }}</h2>
    <p>{{'SectionsTXT' | translate }}</p>
  </div>

  <div class="row">
    <div class="col-lg-3 col-md-6">
      <div class="events-box">
        <img src="../../../../assets/img/products/Product1.jpg" alt="image">
        <div class="content">
          <h4>{{'Foodstuffs' | translate }}</h4>

        </div>

      </div>
    </div>

    <div class="col-lg-3 col-md-6 ">
      <div class="events-box">
        <img src="../../../../assets/img/products/Product2.jpg" alt="image">
        <div class="content">
          <h4>{{'Vegetables_and_Fruits' | translate }}</h4>

        </div>

      </div>
    </div>

    <div class="col-lg-3 col-md-6">
      <div class="events-box">
        <img src="../../../../assets/img/products/Product3.jpg" alt="image">
        <div class="content">
          <h4>{{'Accessories' | translate }}</h4>

        </div>

      </div>
    </div>

    <div class="col-lg-3 col-md-6">
      <div class="events-box">
        <img src="../../../../assets/img/products/Product4.jpg" alt="image">
        <div class="content">
          <h4>{{'Electrical_devices' | translate }}</h4>

        </div>

      </div>
    </div>

    <div class="col-lg-3 col-md-6">
      <div class="events-box">
        <img src="../../../../assets/img/products/Product5.jpg" alt="image">
        <div class="content">
          <h4>{{'Butchery' | translate }}</h4>

        </div>

      </div>
    </div>

    <div class="col-lg-3 col-md-6">
      <div class="events-box">
        <img src="../../../../assets/img/products/Product6.jpg" alt="image">
        <div class="content">
          <h4>{{'Fish' | translate }}</h4>

        </div>

      </div>
    </div>

    <div class="col-lg-3 col-md-6">
      <div class="events-box">
        <img src="../../../../assets/img/products/Product7.jpg" alt="image">
        <div class="content">
          <h4>{{'Bakery' | translate }}</h4>

        </div>

      </div>
    </div>

    <div class="col-lg-3 col-md-6">
      <div class="events-box">
        <img src="../../../../assets/img/products/Product8.jpg" alt="image">
        <div class="content">
          <h4>{{'Offers' | translate }}</h4>

        </div>

      </div>
    </div>



  </div>




</div>
<!-- End How It Works Area -->
