import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-available',
  templateUrl: './not-available.component.html',
  styleUrls: ['./not-available.component.scss']
})
export class NotAvailableComponent implements OnInit {

  TextEn1: string = 'Sorry, Your Request Not Available';
  TextEn2: string = 'Please Check your data and try again';
  TextAr1: string = 'عذراً، طلبك غير متوفر';
  TextAr2: string = 'الرجاء التأكد من صحة البيانات والمحاولة مرة أخرى';


  constructor() { }

  ngOnInit(): void {
  }

}
