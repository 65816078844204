import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppDownloadComponent } from './components/common/app-download/app-download.component';
import { HowItWorksComponent } from './components/common/how-it-works/how-it-works.component';
import { FeedbackStyleOneComponent } from './components/common/feedback-style-one/feedback-style-one.component';
import { CategoryComponent } from './components/common/category/category.component';
import { FeaturesComponent } from './components/common/features/features.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { TeamComponent } from './components/common/team/team.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { DashboardNavbarComponent } from './components/common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from './components/common/dashboard-sidemenu/dashboard-sidemenu.component';


import { HomeComponent } from './components/appviews/home/home.component';
import { AppLoaderComponent } from './components/common/app-loader/app-loader.component';
import { AppComfirmComponent } from './components/common/app-confirm/app-confirm.component';
import { HomeoneBannerComponent } from './components/appviews/home/homeone-banner/homeone-banner.component';
import { InvoiceComponent } from './components/appviews/invoice/invoice.component';
import { CopyrightsComponent } from './components/common/copyrights/copyrights.component';
import { NotAvailableComponent } from './components/appviews/not-available/not-available.component';
import { AppLoaderService } from './components/common/app-loader/app-loader.service';
import { AppConfirmService } from './components/common/app-confirm/app-confirm.service';
import { DatePipe } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}


@NgModule({
  declarations: [
    AppComponent,
    AppDownloadComponent,
    HowItWorksComponent,
    FeedbackStyleOneComponent,

    AppLoaderComponent,
    AppComfirmComponent,

    CategoryComponent,
    FeaturesComponent,
    NavbarStyleOneComponent,
    NavbarStyleTwoComponent,
    NotFoundComponent,
    PartnerComponent,
    TeamComponent,
    FunfactsComponent,
    FooterStyleTwoComponent,
    DashboardNavbarComponent,
    DashboardSidemenuComponent,
    HomeComponent,
    HomeoneBannerComponent,
    InvoiceComponent,
    CopyrightsComponent,
    NotAvailableComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    SelectDropDownModule,
    NgxTypedJsModule,
    FormsModule,
    NgxPaginationModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    ToastrModule.forRoot(),
    
    MatDialogModule
  ],
  providers: [AppConfirmService, DatePipe,
    AppLoaderService],
  bootstrap: [AppComponent]
})
export class AppModule { }
