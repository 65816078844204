import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss']
})
export class HomeoneBannerComponent implements OnInit {

  public SelectedLanguage: string = "en";
  constructor(public translate: TranslateService, private route: ActivatedRoute) {
    if (this.translate.currentLang != undefined) {

      this.SelectedLanguage = this.translate.currentLang;
    }
  }

    ngOnInit(): void {
    


    }

    mainBannerContent = [
        {
        title: 'Categories',
        paragraph: '',
            popularSearchList: [
                {
                title: 'Foodstuffs',
                    link: 'grid-listings-left-sidebar'
                },
                {
                  title: 'Vegetables and Fruits',
                    link: 'grid-listings-left-sidebar'
                },
                {
                  title: 'Accessories',
                    link: 'grid-listings-left-sidebar'
                },
                {
                  title: 'Electrical devices',
                    link: 'grid-listings-left-sidebar'
                },
                {
                  title: 'Butchery',
                    link: 'grid-listings-left-sidebar'
                },
                {
                  title: 'Fish',
                    link: 'grid-listings-left-sidebar'
                },
                {
                  title: 'Bakery',
                    link: 'grid-listings-left-sidebar'
                }
            ]
        }
    ]


  mainBannerContentAr = [
    {
      title: 'التصنيفات',
      paragraph: '',
      popularSearchList: [
        {
          title: 'المواد غذائية',
          link: 'grid-listings-left-sidebar'
        },
        {
          title: 'خضروات وفواكة',
          link: 'grid-listings-left-sidebar'
        },
        {
          title: 'كماليات',
          link: 'grid-listings-left-sidebar'
        },
        {
          title: 'أجهزة كهربائية',
          link: 'grid-listings-left-sidebar'
        },
        {
          title: 'ملحمة',
          link: 'grid-listings-left-sidebar'
        },
        {
          title: 'أسماك',
          link: 'grid-listings-left-sidebar'
        },
        {
          title: 'مخبوزات',
          link: 'grid-listings-left-sidebar'
        }
      ]
    }
  ]

}
