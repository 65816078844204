<app-navbar-style-one></app-navbar-style-one>


<!-- End Page Title Area -->
<!-- Start 404 Error Area -->
<section class="error-area bg-f9f9f9 ptb-100">
  <div class="container">
    <div class="error-content" *ngFor="let Content of errorContent;">
      <img [src]="Content.img" alt="image">
      <h3>{{Content.title}}</h3>
      <p>{{Content.paragraph}}</p>
      <a routerLink="/" class="default-btn">Back to Homepage</a>
    </div>
  </div>
</section>
<!-- End 404 Error Area -->

<app-footer-style-two></app-footer-style-two>
