export const config = {

apiUrl: 'https://alweaam-hm.com/api/',
  apiUrl2: 'https://alweaam-hm.com/',
    authRoles: {
        sa: ['SA'], // Only Super Admin has access
        admin: ['SA', 'Admin'], // Only SA & Admin has access
        editor: ['SA', 'Admin', 'Editor'], // Only SA & Admin & Editor has access
        user: ['SA', 'Admin', 'Editor', 'User'], // Only SA & Admin & Editor & User has access
        guest: ['SA', 'Admin', 'Editor', 'User', 'Guest'] // Everyone has access
    }
}

//apiUrl: 'https://alweaam-hm.com/api/',
//  apiUrl2: 'https://alweaam-hm.com/',

    //apiUrl: 'http://localhost:5083/api/',
    //apiUrl2: 'http://localhost:5083/',
